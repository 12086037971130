import React from 'react';
import toast from 'react-hot-toast';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { BsFolder, BsArrowDownSquare, BsFillXCircleFill } from 'react-icons/bs';
import { IoChevronForward } from 'react-icons/io5';
import { Chart as ChartJS, ArcElement } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

import { useAxios } from '../../hooks';
import {
	Badge,
	Button,
	Input,
	Item,
	Modal,
	Page,
	// Pagination,
	Select,
	Table,
	TextArea,
	Time,
} from '../../components';

ChartJS.register(ArcElement);

const Products = () => {
	const [dashboardItems, setDashboardItems] = React.useState();
	const [showEdit, setShowEdit] = React.useState(false);
	const [currentImages, setCurrentImages] = React.useState([]);
	const [params, setParams] = useSearchParams();

	const {
		register: registerSaveProduct,
		watch: watchSaveProduct,
		setValue: setValueSaveProduct,
		handleSubmit: handleSubmitSaveProduct,
		reset: resetSaveProduct,
		formState: { errors: errorsSaveProduct },
	} = useForm();

	const api = {
		getDashboard: useAxios(),
		getProducts: useAxios(),
		saveProduct: useAxios(),
		removeProduct: useAxios(),
		getProductCategories: useAxios(),
	};

	const getDashboard = () => {
		api.getDashboard.fetch({
			method: 'GET',
			url: '/catalog/admin/dashboard/products',
		});
	};

	const getProducts = () => {
		api.getProducts.fetch({
			method: 'GET',
			url: '/catalog/admin/productList',
		});
	};

	const removeImage = (position) => {
		const images = [...currentImages];
		images.splice(position, 1);
		setCurrentImages(images);
	};

	const saveProduct = async (data) => {
		let contents = [];

		if (currentImages.length) {
			contents = currentImages.filter(
				(image) => typeof image === 'string'
			);
		}

		const body = {
			...data,
			active: !!(data.active === 'true' || data.active === true),
			dateBegin:
				data.dateBegin !== ''
					? moment.utc(data.dateBegin).format()
					: '',
			dateEnd:
				data.dateEnd !== '' ? moment.utc(data.dateEnd).format() : '',
			categoryId: parseInt(data.categoryId, 10),
			subCategoryId: parseInt(data.subCategoryId, 10),
			multiplier: parseInt(data.multiplier, 10),
			score: parseInt(data.score, 10),
			stock: parseInt(data.stock, 10),
			...(contents.length ? { contents } : null),
		};

		const formData = new FormData();
		formData.append('saveProductDTO', JSON.stringify(body));

		currentImages.forEach((image) => {
			if (image instanceof File) {
				formData.append('icon', image);
			}
		});

		api.saveProduct.fetch({
			method: 'POST',
			url: '/catalog/admin/product/save',
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
	};

	const removeProduct = (id) => {
		api.removeProduct.fetch({
			method: 'POST',
			url: `/catalog/admin/product/delete/${id}`,
		});
	};

	const getProductCategories = () => {
		api.getProductCategories.fetch({
			method: 'GET',
			url: '/catalog/admin/category',
		});
	};

	React.useEffect(() => {
		getDashboard();
		getProducts();
		getProductCategories();
	}, []);

	React.useEffect(() => {
		if (api.getDashboard.response) {
			setDashboardItems([
				{
					id: 'toplam',
					name: 'Toplam',
					value: api.getDashboard.response.data.productSize,
					icon: <BsFolder className="h-8 w-8 opacity-25" />,
				},
				{
					id: 'dusuk-stok',
					name: 'Düşük Stok',
					value: api.getDashboard.response.data.lowStockSize,
					icon: <BsArrowDownSquare className="h-8 w-8 opacity-25" />,
				},
				{
					id: 'aktif',
					name: 'Aktif',
					value: api.getDashboard.response.data.activeSize,
					icon: (
						<Doughnut
							options={{
								maintainAspectRatio: false,
								plugins: {
									legend: {
										display: false,
									},
								},
							}}
							data={{
								labels: ['Aktif', 'Pasif'],
								datasets: [
									{
										data: [
											api.getDashboard.response.data
												.activeSize,
											api.getDashboard.response.data
												.passiveSize,
										],
										backgroundColor: ['#a7f3d0', '#F8FAFC'],
										borderWidth: 0,
									},
								],
							}}
							className="pointer-events-none h-14 w-14"
						/>
					),
				},
				{
					id: 'pasif',
					name: 'Pasif',
					value: api.getDashboard.response.data.passiveSize,
					icon: (
						<Doughnut
							options={{
								maintainAspectRatio: false,
								plugins: {
									legend: {
										display: false,
									},
								},
							}}
							data={{
								labels: ['Pasif', 'Aktif'],
								datasets: [
									{
										data: [
											api.getDashboard.response.data
												.passiveSize,
											api.getDashboard.response.data
												.activeSize,
										],
										backgroundColor: ['#fecdd3', '#F8FAFC'],
										borderWidth: 0,
									},
								],
							}}
							className="pointer-events-none h-14 w-14"
						/>
					),
				},
			]);
		}
	}, [api.getDashboard.response]);

	React.useEffect(() => {
		if (api.getProducts.error) {
			toast.error(api.getProducts.error.message);
		}
	}, [api.getProducts.error]);

	React.useEffect(() => {
		if (api.saveProduct.response) {
			resetSaveProduct();
			setCurrentImages([]);
			setShowEdit(false);
			toast.success('Kaydedildi.');
			getProducts();
		}
	}, [api.saveProduct.response]);

	React.useEffect(() => {
		if (api.saveProduct.error) {
			toast.error(api.saveProduct.error.message);
		}
	}, [api.saveProduct.error]);

	React.useEffect(() => {
		if (params.get('edit') !== null) {
			setParams({});
			setShowEdit(true);
		}
	}, [params.get('edit')]);

	React.useEffect(() => {
		if (
			showEdit.id &&
			api.getProducts.response &&
			api.getProductCategories.response
		) {
			const { id } = showEdit;
			const item =
				api.getProducts.response.data[
					api.getProducts.response.data.findIndex(
						(object) => object.id === id
					)
				];
			const values = [
				{ name: item.name },
				{ description: item.description },
				{ code: item.code },
				{ score: item.score },
				{ multiplier: item.multiplier },
				{ stock: item.stock },
				{
					dateBegin: moment(item.dateBegin).format(
						'YYYY-MM-DD HH:mm:ss'
					),
				},
				{ dateEnd: moment(item.dateEnd).format('YYYY-MM-DD HH:mm:ss') },
				{ topIcon: item.topIcon },
				{ topIconText: item.topIconText },
				{ categoryId: item.categoryId },
				{ subCategoryId: item.subCategoryId },
				{ active: item.active },

				// { //TODO: item.productDiscountDTO?.discount gonderince hata veriyor api 500
				// 	...(item.productDiscountDTO?.discount
				// 		? {
				// 				'productDiscountDTO.discount':
				// 					item.productDiscountDTO.discount,
				// 		  }
				// 		: null),
				// },
			];

			values.forEach((value) => {
				setValueSaveProduct(
					Object.keys(value)[0],
					Object.values(value)[0],
					{ shouldValidate: true }
				);
			});

			if (
				item.productContentUrlList &&
				Array.isArray(item.productContentUrlList)
			) {
				const images = [];
				item.productContentUrlList.forEach((image) => {
					images.push(image.baseAddress);
				});
				setCurrentImages(images);
			}
		}
	}, [
		showEdit.id &&
			api.getProducts.response &&
			api.getProductCategories.response,
	]);

	React.useEffect(() => {
		if (api.removeProduct.response) {
			setCurrentImages([]);
			setShowEdit(false);
			toast.success('Silindi.');
			getProducts();
		}
	}, [api.removeProduct.response]);

	React.useEffect(() => {
		if (api.removeProduct.error) {
			toast.error(api.removeProduct.error.message);
		}
	}, [api.removeProduct.error]);

	React.useEffect(() => {
		if (api.getProductCategories.error) {
			toast.error(api.getProductCategories.error.message);
		}
	}, [api.getProductCategories.error]);

	return (
		<Page>
			<Page.Header header="Puanlı Ürünler" title="Tüm Ürünler">
				{/* <Page.Header.Search show />
				<Page.Header.Filter show /> */}
				<Page.Header.Add show onClick={() => setShowEdit(true)} />
			</Page.Header>

			<Page.Body
				loading={api.getProducts.loading}
				show={!api.getProducts.loading && api.getProducts.response}
			>
				<Page.Dashboard items={dashboardItems} />
				<Page.Content>
					<Table>
						<Table.Head
							data={[
								'Id',
								'Ürün',
								'Kategori',

								'Puan',
								'Satılan',
								'Tarih',
								'Durum',
								'',
							]}
						/>
						<Table.Body>
							{api.getProducts.response?.data?.length &&
								api.getProducts.response.data.map(
									(item, index) => (
										<Table.Row key={index}>
											<Table.Column>
												<span className="whitespace-nowrap text-xs font-normal text-slate-400">
													{item.id}
												</span>
											</Table.Column>
											<Table.Column>
												<Item
													image={
														item.productContentUrlList &&
														item
															.productContentUrlList[0] &&
														item
															.productContentUrlList[0]
															.baseAddress
															? item
																	.productContentUrlList[0]
																	.baseAddress
															: require('../../assets/logo-connection.png')
													}
													title={item.name}
													description={
														item.description
													}
													className="w-48 [&_img]:object-contain [&_img]:p-2 [&_img]:bg-white"
												/>
											</Table.Column>
											<Table.Column>
												<div className="flex items-center gap-1 truncate whitespace-nowrap text-xs">
													{item.categoryName}
													{item.subCategoryName && (
														<>
															<IoChevronForward className="h-3 w-3 opacity-50" />
															{
																item.subCategoryName
															}
														</>
													)}
												</div>
											</Table.Column>

											<Table.Column>
												<div className="text-base font-medium">
													{item.score}
												</div>
												<div className="mt-1 text-xs text-slate-400">
													Oran: {item.multiplier || 1}
												</div>
											</Table.Column>
											<Table.Column>
												<div className="text-base font-medium">
													{item.sold ? item.sold : 0}
												</div>
												<div className="mt-1 text-xs text-slate-400">
													Stok: {item.stock}
												</div>
											</Table.Column>
											<Table.Column>
												<Time
													dateBegin={moment(
														item.dateBegin
													).format('DD.MM.YYYY')}
													timeBegin={moment(
														item.dateBegin
													).format('HH:mm:ss')}
													dateEnd={moment(
														item.dateEnd
													).format('DD.MM.YYYY')}
													timeEnd={moment(
														item.dateEnd
													).format('HH:mm:ss')}
												/>
											</Table.Column>
											<Table.Column>
												{item.active ? (
													<Badge variant="success">
														<div className="aspect-square w-1.5 animate-pulse rounded-full bg-green-500" />
														Aktif
													</Badge>
												) : (
													<Badge variant="danger">
														<div className="aspect-square w-1.5 rounded-full bg-red-500" />
														Pasif
													</Badge>
												)}
											</Table.Column>
											<Table.Column className="text-right [&_button]:w-20">
												<Button
													size="xs"
													variant="secondary-outline"
													className="inline-flex"
													onClick={() => {
														setShowEdit({
															id: item.id,
														});
													}}
												>
													Düzenle
												</Button>
											</Table.Column>
										</Table.Row>
									)
								)}
						</Table.Body>
					</Table>
					{/* <Pagination /> */}
				</Page.Content>
			</Page.Body>
			<Page.Footer />

			<Modal
				show={Boolean(showEdit)}
				loading={
					api.getProducts.loading ||
					api.saveProduct.loading ||
					api.getProductCategories.loading ||
					api.removeProduct.loading
				}
				size="lg"
				title={!showEdit.id ? 'Yeni Ekle' : 'Düzenle'}
				onClose={() => {
					setShowEdit(false);
					setCurrentImages([]);
					resetSaveProduct();
				}}
			>
				<form
					onSubmit={handleSubmitSaveProduct(saveProduct)}
					noValidate
				>
					<div className="flex flex-col gap-4 p-6">
						{/* images start */}
						<div className="group">
							<div className="grid grid-cols-3 items-center">
								<label className="col-span-1 flex gap-1 text-sm font-normal text-slate-700">
									Görsel
								</label>
								<div className="col-span-2">
									<div className="mb-4 grid grid-cols-4 gap-4">
										{currentImages &&
										currentImages.length ? (
											currentImages.map(
												(image, index) => (
													<div
														className="relative inline-block"
														key={index}
													>
														<img
															src={
																typeof image ===
																'object'
																	? window.URL.createObjectURL(
																			image
																	  )
																	: image
															}
															key={index}
															alt=""
															className="aspect-square h-full w-full rounded-lg object-cover shadow bg-slate-50"
														/>
														<button
															type="button"
															className="absolute top-0 right-0 translate-x-2 -translate-y-2"
															onClick={() =>
																removeImage(
																	index
																)
															}
														>
															<BsFillXCircleFill className="translate h-5 w-5 rounded-full border-2 bg-white border-white text-red-500" />
														</button>
													</div>
												)
											)
										) : (
											<div className="col-span-4 rounded-md p-3 text-center text-xs text-slate-400 bg-slate-50">
												Görsel Bulunamadı
											</div>
										)}
									</div>
									<div className="relative">
										<input
											type="file"
											accept="image/*"
											onChange={(event) => {
												if (event?.target?.files?.[0]) {
													setCurrentImages([
														...currentImages,
														event.target.files[0],
													]);
												}
											}}
											className="absolute left-0 top-0 h-full w-full cursor-pointer opacity-0"
										/>
										<div
											className={`flex h-9 w-full items-center justify-center whitespace-nowrap rounded-lg border-2 px-3.5 text-center text-sm font-normal leading-none transition duration-300 border-transparent text-white bg-blue-500 hover:bg-blue-600
										`}
										>
											Yeni Görsel Ekle
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* images end */}

						<Input
							type="text"
							name="name"
							label="İsim"
							grid
							placeholder="Lütfen giriniz."
							register={registerSaveProduct}
							validation={{
								required: 'Bu alan zorunludur.',
							}}
							errors={errorsSaveProduct}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
						<Input
							type="text"
							name="code"
							label="Kod"
							grid
							placeholder="Lütfen giriniz."
							register={registerSaveProduct}
							validation={{
								required: 'Bu alan zorunludur.',
							}}
							errors={errorsSaveProduct}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
						<TextArea
							name="description"
							label="Açıklama"
							grid
							placeholder="Lütfen giriniz."
							register={registerSaveProduct}
							validation={{
								required: 'Bu alan zorunludur.',
							}}
							errors={errorsSaveProduct}
							size="lg"
							className={{ input: '!text-sm' }}
						/>

						<Select
							name="categoryId"
							label="Kategori"
							grid
							options={[
								...[{ name: 'Lütfen seçiniz.', value: '' }],
								...(api.getProductCategories.response?.data
									?.length
									? api.getProductCategories.response.data.map(
											(item) => ({
												name: item.name,
												value: item.id,
											})
									  )
									: []),
							]}
							defaultValue=""
							register={registerSaveProduct}
							validation={{
								required: 'Bu alan zorunludur.',
								onChange: () => {
									setValueSaveProduct('subCategoryId', '');
								},
							}}
							errors={errorsSaveProduct}
							size="lg"
							className={{ select: '!text-sm' }}
						/>
						<Select
							name="subCategoryId"
							label="Alt Kategori"
							grid
							options={[
								...[{ name: 'Lütfen seçiniz.', value: '' }],
								...(api.getProductCategories.response?.data[
									api.getProductCategories.response?.data?.findIndex(
										(object) =>
											object.id ===
											parseInt(
												watchSaveProduct('categoryId'),
												10
											)
									)
								]?.subCategoryList?.length
									? api.getProductCategories.response.data[
											api.getProductCategories.response.data.findIndex(
												(object) =>
													object.id ===
													parseInt(
														watchSaveProduct(
															'categoryId'
														),
														10
													)
											)
									  ].subCategoryList.map((item) => ({
											name: item.name,
											value: item.id,
									  }))
									: []),
							]}
							disabled={!watchSaveProduct('categoryId')}
							defaultValue=""
							register={registerSaveProduct}
							validation={{
								required: 'Bu alan zorunludur.',
							}}
							errors={errorsSaveProduct}
							size="lg"
							className={{ select: '!text-sm' }}
						/>
						<Input
							type="number"
							name="score"
							label="Puan"
							grid
							placeholder="Lütfen giriniz."
							register={registerSaveProduct}
							validation={{
								required: 'Bu alan zorunludur.',
							}}
							errors={errorsSaveProduct}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
						<Input
							type="number"
							name="multiplier"
							label="Oran"
							grid
							placeholder="Lütfen giriniz."
							register={registerSaveProduct}
							validation={{
								required: 'Bu alan zorunludur.',
							}}
							errors={errorsSaveProduct}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
						{/*
						TODO: gonderince api 500 veriyor
						<Input
							type="number"
							name="productDiscountDTO.discount"
							label="İndirim Oranı"
							grid
							placeholder="Lütfen giriniz."
							register={registerSaveProduct}
							validation={{
								required: 'Bu alan zorunludur.',
								min: {
									value: 0,
									message: 'Bu alan en az 0 olmalıdır.',
								},
								max: {
									value: 100,
									message: 'Bu alan en fazla 100 olmalıdır.',
								},
							}}
							errors={errorsSaveProduct}
							size="lg"
							className={{ input: '!text-sm' }}
						/> */}
						<Input
							type="number"
							name="stock"
							label="Stok"
							grid
							placeholder="Lütfen giriniz."
							register={registerSaveProduct}
							validation={{
								required: 'Bu alan zorunludur.',
							}}
							errors={errorsSaveProduct}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
						{/*
						TODO: response icinde gelmiyor kaldirdim.
						<Select
							name="unlimitedOrder"
							label="Sınırsız Sipariş"
							grid
							options={[
								{
									name: 'Evet',
									value: true,
								},
								{
									name: 'Hayır',
									value: false,
								},
							]}
							defaultValue
							register={registerSaveProduct}
							validation={{
								required: 'Bu alan zorunludur.',
							}}
							errors={errorsSaveProduct}
							size="lg"
							className={{ select: '!text-sm' }}
						/> */}
						<Input
							type="datetime-local"
							name="dateBegin"
							label="Başlangıç Tarihi"
							grid
							placeholder="Lütfen giriniz."
							defaultValue={moment(Date.now()).format(
								'YYYY-MM-DD HH:mm'
							)}
							register={registerSaveProduct}
							errors={errorsSaveProduct}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
						<Input
							type="datetime-local"
							name="dateEnd"
							label="Bitiş Tarihi"
							grid
							placeholder="Lütfen giriniz."
							defaultValue={moment(Date.now())
								.add(1, 'year')
								.format('YYYY-MM-DD HH:mm')}
							register={registerSaveProduct}
							errors={errorsSaveProduct}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
						<Select
							name="topIcon"
							label="Üst İkon"
							grid
							options={[
								{
									name: 'Var',
									value: 'true',
								},
								{
									name: 'Yok',
									value: 'false',
								},
							]}
							defaultValue=""
							register={registerSaveProduct}
							errors={errorsSaveProduct}
							size="lg"
							className={{ select: '!text-sm' }}
						/>
						<Input
							type="text"
							name="topIconText"
							label="Üst İkon Metni"
							grid
							placeholder="Lütfen giriniz."
							register={registerSaveProduct}
							errors={errorsSaveProduct}
							size="lg"
							className={{ input: '!text-sm' }}
						/>
						<Select
							name="active"
							label="Durum"
							grid
							options={[
								{
									name: 'Aktif',
									value: true,
								},
								{
									name: 'Pasif',
									value: false,
								},
							]}
							defaultValue
							register={registerSaveProduct}
							// validation={{
							// 	required: 'Bu alan zorunludur.',
							// }}
							errors={errorsSaveProduct}
							size="lg"
							className={{ select: '!text-sm' }}
						/>
					</div>

					{showEdit.id && (
						<Input
							type="hidden"
							name="id"
							register={registerSaveProduct}
							defaultValue={showEdit.id}
						/>
					)}

					<div className="flex items-center justify-center gap-3 border-t-2 px-4 py-3 border-slate-100">
						{showEdit.id && (
							<Button
								type="button"
								variant="danger-outline"
								size="md"
								className="w-32"
								onClick={() => {
									removeProduct(showEdit.id);
								}}
							>
								Sil
							</Button>
						)}
						<Button
							type="submit"
							variant="primary"
							size="md"
							disabled={Object.keys(errorsSaveProduct).length > 0}
							className="w-32"
						>
							Kaydet
						</Button>
					</div>
				</form>
			</Modal>
		</Page>
	);
};

export default Products;
