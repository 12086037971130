import React from 'react';
import { Page } from '../../components';

const GameWordPuzzle = () => {
	const [showEdit, setShowEdit] = React.useState(false);
	return (
		<Page>
			<Page.Header header="Oyunlar" title="Kelime Bulmaca" />
		</Page>
	);
};

export default GameWordPuzzle;
